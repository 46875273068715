<template>
  <div class="hero">
    <div class="hero__logo-holder">
      <img
        class="top-logo"
        src="@/assets/images/top-logo.svg"
        alt="Agencija 101"
      />
    </div>
    <div class="container">
      <div class="hero__content">
        <h1 class="hero__heading">
          {{ $t("hero.title") }}
        </h1>
        <p class="hero__text">
          {{ $t("hero.text") }}
        </p>
      </div>
    </div>
    <div class="hero__trees">
      <div class="hero__trees--holder">
        <img class="hero__trees--image" src="@/assets/images/trees.svg" />
        <div class="dots-container dots-container-1">
          <img class="dot dot-1" src="@/assets/images/dot.svg" />
          <img class="dot dot-2" src="@/assets/images/dot.svg" />
          <img class="dot dot-3" src="@/assets/images/dot.svg" />
          <img class="dot dot-4" src="@/assets/images/dot.svg" />
          <img class="dot dot-5" src="@/assets/images/dot.svg" />
          <img class="dot dot-6" src="@/assets/images/dot.svg" />
          <img class="dot dot-7" src="@/assets/images/dot.svg" />
          <img class="dot dot-8" src="@/assets/images/dot.svg" />
        </div>
        <div class="dots-container dots-container-1 dots-container-2">
          <img class="dot dot-1" src="@/assets/images/dot.svg" />
          <img class="dot dot-2" src="@/assets/images/dot.svg" />
          <img class="dot dot-3" src="@/assets/images/dot.svg" />
          <img class="dot dot-4" src="@/assets/images/dot.svg" />
          <img class="dot dot-5" src="@/assets/images/dot.svg" />
          <img class="dot dot-6" src="@/assets/images/dot.svg" />
          <img class="dot dot-7" src="@/assets/images/dot.svg" />
          <img class="dot dot-8" src="@/assets/images/dot.svg" />
        </div>
      </div>
      <div class="hero__trees--small">
        <img class="hero__trees--image-2" src="@/assets/images/trees-2.svg" />
      </div>
    </div>
    <snowfall class="hero__snowfall"></snowfall>
    <div class="hero__lang-switcher">
      <div class="container">
        <p>
          <a :href="$t('hero.oppositeLangUrl')">
            {{ $t("hero.oppositeLang") }}
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Snowfall from "../Snowfall.vue";

export default {
  components: {
    Snowfall,
  },

  data() {
    return {};
  },

  mounted() {
    let bulbs = document.querySelectorAll(".dot");
    for (let bulb of bulbs) {
      bulb.style.animationDelay =
        (Math.random() * (1.2 - 0.04) + 0.04).toFixed(4) + "s";
    }
  },

  methods: {},
};
</script>

<style>
</style>