<template>
  <div>
    <hero></hero>
    <content></content>
    <references></references>
    <video-modal></video-modal>
  </div>
</template>

<script>
import Hero from "@/components/sections/Hero";
import Content from "@/components/sections/Content";
import References from "@/components/sections/References";
import VideoModal from "@/components/VideoModal";

export default {
  components: {
    Hero,
    Content,
    References,
    VideoModal,
  },

  mounted() {
    this.setVh();

    window.addEventListener("resize", () => {
      this.setVh();
    });

    document.title = this.$t("pageTitle");
  },

  methods: {
    setVh() {
      document.documentElement.style.setProperty(
        "--vh",
        `${window.innerHeight}px`
      );
    },
  },
};
</script>