<template>
  <div class="video-modal">
    <div class="video-modal__backdrop" @click="hideModal()"></div>
    <div class="video-modal__video">
      <div class="embed-container">
        <iframe
          id="video0"
          :src="videoSrc"
          frameborder="0"
          webkitAllowFullScreen
          mozallowfullscreen
          allowFullScreen
          allow="autoplay"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import Player from "@vimeo/player";

export default {
  data() {
    return {
      videoSrc: "https://player.vimeo.com/video/783282040?h=8a3f11fde4",
    };
  },

  mounted() {
    // keep whole video visible in viewport
    this.setProperWidth();
    window.addEventListener("resize", () => {
      this.setProperWidth();
    });

    this.emitter.on("video-set", (videoSrc) => {
      // set video src
      this.videoSrc = videoSrc;
    });

    let player;

    this.emitter.on("video-play", () => {
      // play video
      player = new Player(document.getElementById("video0"));
      player.play();
      this.showModal();
    });

    this.emitter.on("video-stop", () => {
      player.pause();
      this.hideModal();
    });

    // close on esc
    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        this.hideModal();
      }
    });
  },

  methods: {
    showModal() {
      document.body.classList.add("overflow-hidden");
      document.querySelector(".video-modal").classList.add("z-index");
      document.querySelector(".video-modal").classList.add("opacity-1");
    },

    hideModal() {
      document.querySelector(".video-modal").classList.remove("opacity-1");
      setTimeout(() => {
        document.querySelector(".video-modal").classList.remove("z-index");
        document.body.classList.remove("overflow-hidden");
        let player = new Player(document.getElementById("video0"));
        player.pause();
      }, 300);
    },

    setProperWidth() {
      let element = document.querySelector(".video-modal__video");
      let viewportHeight = window.innerHeight;
      let proper_width = (viewportHeight * 16) / 9;
      element.style.width = proper_width + "px";
    },
  },
};
</script>

<style>
</style>