import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import App from './App.vue'
import '@/assets/styles/_import.scss'
import mitt from 'mitt'
const emitter = mitt()
import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/views/Home.vue'

const routes = [
    { path: '/', component: Home },
    { path: '/en', component: Home },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

function loadLocaleMessages() {
    const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const messages = {}
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            messages[locale] = locales(key)
        }
    })
    return messages
}

let locale = 'sl';
if (window.location.pathname === '/en') {
    locale = 'en'
}

const i18n = createI18n({
    locale: locale,
    fallbackLocale: 'sl',
    messages: loadLocaleMessages()
})


const app = createApp(App)
app.use(router)
app.use(i18n)
app.config.globalProperties.emitter = emitter
app.mount('#app')
