<template>
  <div class="content">
    <div class="container">
      <p class="content__text">
        {{ $t("content.text") }}
      </p>
      <div class="buttons">
        <div class="buttons__button">
          <a :href="$t('anchors.href2')">{{ $t("content.btn1") }}</a>
        </div>
        <div class="buttons__button">
          <a :href="$t('anchors.href1')" v-html="$t('content.btn2')"></a>
        </div>
      </div>
      <div class="description" :id="$t('anchors.a1')">
        <h2 class="description__title">
          {{ $t("content.description.title") }}
        </h2>
        <p class="description__text">
          {{ $t("content.description.text") }}
        </p>
      </div>

      <div class="how-it-works">
        <h3 class="how-it-works__title">{{ $t("content.how.title") }}</h3>
        <p class="how-it-works__text" v-html="$t('content.how.text')"></p>
      </div>

      <div class="videos-section">
        <h3>{{ $t("content.videos.title") }}</h3>
        <video-player></video-player>
      </div>
    </div>
  </div>
</template>

<script>
import VideoPlayer from "../VideoPlayer.vue";

export default {
  components: {
    VideoPlayer,
  },
};
</script>

<style>
</style>