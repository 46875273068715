<template>
  <div class="video-player">
    <div class="video-player__current-video">
      <div class="bg-spinner"></div>
      <div class="thumbnail-play" v-if="currentVideo" @click="playVideo">
        <img
          :src="
            require(`@/assets/images/thumbnails/${currentVideo.thumbnail}.jpg`)
          "
        />
        <div class="play-icon-overlay">
          <img src="@/assets/images/play-btn.svg" />
        </div>
      </div>
    </div>
    <div class="video-player__switcher">
      <div
        class="video-player__switcher--image-container"
        v-for="video in videos"
        :key="video.id"
        @click="setCurrent(video)"
      >
        <img
          :src="require(`@/assets/images/thumbnails/${video.thumbnail}.jpg`)"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentVideo: null,
      videos: [
        {
          id: 1,
          src: "https://player.vimeo.com/video/783282040?h=8a3f11fde4",
          thumbnail: "t1",
        },
        {
          id: 2,
          src: "https://player.vimeo.com/video/783282595?h=623d7487b8",
          thumbnail: "t2",
        },
        {
          id: 3,
          src: "https://player.vimeo.com/video/783282738?h=9e6c5ddc36",
          thumbnail: "t3",
        },
        {
          id: 4,
          src: "https://player.vimeo.com/video/783282900?h=867e40cb7e",
          thumbnail: "t4",
        },
        {
          id: 5,
          src: "https://player.vimeo.com/video/783283055?h=41d33773b3",
          thumbnail: "t5",
        },
        {
          id: 6,
          src: "https://player.vimeo.com/video/783283219?h=0d18ec8476",
          thumbnail: "t6",
        },
      ],
    };
  },

  mounted() {
    // by default set first video as current
    this.currentVideo = this.videos[0];
  },

  methods: {
    setCurrent(video) {
      this.currentVideo = video;

      // set video in modal so its ready when user clicks play
      this.emitter.emit("video-set", this.currentVideo.src);
    },

    playVideo() {
      // play video in modal
      this.emitter.emit("video-play");
    },
  },
};
</script>

<style>
</style>