<template>
  <div class="references" :id="$t('anchors.a2')">
    <div class="container">
      <h2>{{ $t("references") }}</h2>

      <ol>
        <li>
          <span>1.&nbsp;&nbsp;&nbsp;</span>
          <a target="_blank" href="https://www.mdpi.com/2076-328X/3/3/459/htm"
            >https://www.mdpi.com/2076-328X/3/3/459/htm</a
          >
        </li>
        <li>
          <span>2.&nbsp;&nbsp;&nbsp;</span>
          <a
            href="https://www.bbc.com/worklife/article/20170719-how-moments-of-boredom-help-us-achieve-more"
            >https://www.bbc.com/worklife/article/20170719-how-moments-of-boredom-help-us-achieve-more</a
          >
        </li>

        <li>
          <span>3.&nbsp;&nbsp;&nbsp;</span>
          <a
            target="_blank"
            href="https://www.bbc.com/culture/article/20200522-how-boredom-can-spark-creativity"
            >https://www.bbc.com/culture/article/20200522-how-boredom-can-spark-creativity</a
          >
        </li>

        <li>
          <span>4.&nbsp;&nbsp;&nbsp;</span>
          <a
            target="_blank"
            href="https://medium.com/illumination/being-bored-can-boost-your-creativity-b8064e392e08"
            >https://medium.com/illumination/being-bored-can-boost-your-creativity-b8064e392e08</a
          >
        </li>

        <li>
          <span>5.&nbsp;&nbsp;&nbsp;</span>
          <a
            target="_blank"
            href="https://killerinnovations.com/innovation-from-boredom/"
            >https://killerinnovations.com/innovation-from-boredom/</a
          >
        </li>

        <li>
          <span>6.&nbsp;&nbsp;&nbsp;</span>
          <a
            target="_blank"
            href="https://markmanson.net/boring-ways-to-become-more-creative"
            >https://markmanson.net/boring-ways-to-become-more-creative</a
          >
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>